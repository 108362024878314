import queryString from 'query-string';

import { UNISSU_FE_LOGIN_URL, UNISSU_FE_SIGNUP_URL } from 'libs/constants';

const unissuAuth = (url: string) => {
  if (window) {
    const params = queryString.stringify({
      redirectPath: window.location.pathname
    });
    window.location.href = `${url}?${params}`;
  }
};

export const unissuLogin = () => unissuAuth(UNISSU_FE_LOGIN_URL);

export const unissuSignUp = () => unissuAuth(UNISSU_FE_SIGNUP_URL);
