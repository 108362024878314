import axios from 'axios';
import { GetServerSidePropsContext, NextPageContext } from 'next';

import { requestSdk, get, post, patch } from 'libs/sdk';

import {
  ICalculateEventTicketPriceResponse,
  IEventTicketTypeRetrieveEventTicketType,
  IPaymentRetrievePayment,
  ICreateEventTicketPaymentResponse,
  IOrganiserMemberListMember,
  IOrganiserRetrieveOrganiser,
  IOrganiserSessionListSession,
  IOrganiserEventListEvent,
  ISpeakerSessionAttendeeInvitationListAttendeeInvitation,
  IGlobalSearchUserListUser,
  IGlobalSearchEventListEvent,
  IGlobalSearchSessionListSession,
  IVideoLibrarySessionListSession,
  ICategoryDetailAndLiveListRoundTableItem,
  IVideoLibraryFeaturedSessionListSession,
  ICategoryDetailAndLiveListSessionItem,
  ISessionSlotPurchaseSessionCreateResponse,
  IEventTicketTypeListTicketType,
  IEventListEvent,
  PaginatedList,
  IEventDetailEvent,
  ICategoryListCategory,
  ICategoryDetailCategory,
  ISessionDetailSession,
  IMeUser,
  IFile,
  ISignature,
  ISessionUpdateResponse,
  ISessionSpeakerDetailSession,
  ISessionSlotListSlot,
  IMessage,
  IPrivateChatPreviewListChat,
  IPrivateChatGetOrCreateChat,
  ISessionDetailSessionAttendee,
  IMeetingSlotsRetrieveResponse,
  IScheduleRetrieveMeetingItem,
  IScheduleRetrieveSessionItem,
  IUserRetrieveUser,
  ISessionParticipantsUpdateResponse,
  IUnreadMessagesCountResponse,
  IHolisticsSign,
  IAdBanner,
  INotificationListNotification,
  ISessionSpeakerDetailSessionPrerecordedVideo,
  IFeaturedEventListEvent,
  IMeetingAndRoundTableJitsiInfoResponse,
  IRoundTableDetailRoundTable,
  IValidateCouponResponse
} from './types';

export const categoryList = ({
  context
}: {
  context?: GetServerSidePropsContext;
} = {}) =>
  requestSdk<PaginatedList<ICategoryListCategory>>(() =>
    get({ url: '/events/categories/list/', context })
  );

export const categoryDetail = ({
  slug,
  context
}: {
  slug?: string | string[];
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<ICategoryDetailCategory>(() =>
    get({ url: `/events/categories/${slug}/retrieve/`, context })
  );

export const sessionDetail = ({
  sessionId,
  context
}: {
  sessionId?: number | string | string[];
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<ISessionDetailSession>(() =>
    get({ url: `/events/sessions/${sessionId}/retrieve/`, context })
  );

export const sessionSpeakerDetail = ({
  sessionId,
  context
}: {
  sessionId: string | string[];
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<ISessionSpeakerDetailSession>(() =>
    get({ url: `/events/speaker/sessions/${sessionId}/retrieve/`, context })
  );

// Doesn't have the "context" argument because the signature should not be executed on the server.
export const sessionFileSign = ({
  data
}: {
  data: { filename: string; file_type: string };
}) =>
  requestSdk<ISignature>(() =>
    post({ url: '/events/session-file/sign/', data })
  );

/* `Context` is not an argument here because it's not intended to be used with context.
 * Pure `axios.post` is used because the `post` wrapper always adds Authorization headers
 * which are not expected by AWS and the request is rejected. */
export const uploadFile = ({ url, data }: { url: string; data: any }) =>
  requestSdk(() => axios.post(url, data));

export const sessionUpdate = ({
  sessionId,
  data,
  context
}: {
  sessionId: number | string;
  data: {
    title: string;
    description: string;
    files: Array<IFile>;
    prerecorded_video: null | ISessionSpeakerDetailSessionPrerecordedVideo;
  };
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<ISessionUpdateResponse>(() =>
    patch({
      url: `/events/speaker/sessions/${sessionId}/basic-info/update/`,
      data,
      context
    })
  );

export const sessionPrerecordedVideoSign = ({
  sessionId,
  data,
  context
}: {
  sessionId: number | string;
  data: { filename: string };
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<{ id: number; filename: string; upload_url: string }>(() =>
    post({
      url: `/events/speaker/sessions/${sessionId}/prerecorded-video/sign/`,
      data,
      context
    })
  );

export const sessionParticipantsUpdate = ({
  sessionId,
  data,
  context
}: {
  sessionId: number;
  data: {
    participants: Array<number>;
    invite_emails: Array<string>;
  };
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<ISessionParticipantsUpdateResponse>(() =>
    patch({
      url: `/events/speaker/sessions/${sessionId}/participants/update/`,
      data,
      context
    })
  );

export const liveSessionList = ({
  context
}: {
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<
    PaginatedList<
      | ICategoryDetailAndLiveListSessionItem
      | ICategoryDetailAndLiveListRoundTableItem
    >
  >(() => get({ url: `/events/sessions/live/`, context }));

export const eventDetail = ({
  context
}: {
  context?: GetServerSidePropsContext | NextPageContext;
} = {}) =>
  requestSdk<IEventDetailEvent>(() =>
    get({ url: '/events/event/retrieve/', context })
  );

export const userDetail = ({
  context
}: {
  context?: GetServerSidePropsContext;
} = {}) => requestSdk<IMeUser>(() => get({ url: '/users/me/', context }));

export const trackSessionPageView = ({
  sessionId,
  context
}: {
  sessionId: number | string | string[];
  context?: GetServerSidePropsContext;
}) =>
  requestSdk(() =>
    post({
      url: `/analytics/sessions/${sessionId}/impression/create/`,
      context
    })
  );

export const sessionInvitationAccept = ({
  token,
  context
}: {
  token: string | string[];
  context?: GetServerSidePropsContext;
}) =>
  requestSdk(() =>
    post({ url: `/events/session-invite/${token}/accept/`, context })
  );

export const sessionSlotList = ({
  params,
  context
}: {
  params: { category_slug: string };
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<Array<ISessionSlotListSlot>>(() =>
    get({ url: `/events/session-slots/list/`, params, context })
  );

export const sessionSlotPurchaseSessionCreate = ({
  data,
  context
}: {
  data: {
    slot_inputs: Array<{
      slot: number;
      with_data_package: boolean;
    }>;
    success_url: string;
    cancel_url: string;
  };
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<ISessionSlotPurchaseSessionCreateResponse>(() =>
    post({ url: '/shop/session-slots-checkout/', data, context })
  );

export const scheduleRetrieve = ({
  context
}: {
  context?: GetServerSidePropsContext;
} = {}) =>
  requestSdk<{
    items: Array<IScheduleRetrieveMeetingItem | IScheduleRetrieveSessionItem>;
  }>(() => get({ url: '/events/schedule/retrieve/', context }));

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const sessionAddToSchedule = ({
  sessionId
}: {
  sessionId: number | string | string[];
}) =>
  requestSdk(() => post({ url: `/events/schedule/session/${sessionId}/add/` }));

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const sessionRemoveFromSchedule = ({
  sessionId
}: {
  sessionId: number | string | string[];
}) =>
  requestSdk(() =>
    post({ url: `/events/schedule/session/${sessionId}/remove/` })
  );

export const messageList = ({
  chatId,
  context
}: {
  chatId: number;
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<PaginatedList<IMessage>>(() =>
    get({ url: `/chat/${chatId}/messages/list/`, context })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const nextMessagesList = ({ nextUrl }: { nextUrl: string }) =>
  requestSdk<PaginatedList<IMessage>>(() => get({ url: nextUrl, apiUrl: '' }));

export const privateChatPreviewList = ({
  limit,
  context
}: {
  limit: number;
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<PaginatedList<IPrivateChatPreviewListChat>>(() =>
    get({ url: `/chat/private/list/`, params: { limit }, context })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const nextPrivateChatPreviewList = ({ nextUrl }: { nextUrl: string }) =>
  requestSdk<PaginatedList<IPrivateChatPreviewListChat>>(() =>
    get({ url: nextUrl, apiUrl: '' })
  );

export const markUnreadMessagesSeen = ({
  chatId,
  context
}: {
  chatId: number;
  context?: GetServerSidePropsContext;
}) =>
  requestSdk(() =>
    post({ url: `/chat/private/${chatId}/messages/mark-seen/`, context })
  );

export const getSessionAttendees = ({
  sessionId,
  context
}: {
  sessionId: string | string[];
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<PaginatedList<ISessionDetailSessionAttendee>>(() =>
    get({ url: `/events/sessions/${sessionId}/attendees/list`, context })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const getOrCreateChat = ({ receivers }: { receivers: number[] }) =>
  requestSdk<IPrivateChatGetOrCreateChat>(() =>
    post({ url: '/chat/private/get-or-create/', data: { receivers } })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const createMessage = ({
  content,
  chatId
}: {
  content: string;
  chatId: number;
}) =>
  requestSdk<IMessage>(() =>
    post({ url: `/chat/${chatId}/messages/create/`, data: { content } })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const ratingCreate = ({
  sessionId,
  value
}: {
  sessionId: number;
  value: number;
}) =>
  requestSdk(() =>
    post({
      url: `/events/sessions/${sessionId}/rate/`,
      data: { value }
    })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const trackSessionFileDownload = ({
  sessionFileId
}: {
  sessionFileId: number;
}) =>
  requestSdk(() =>
    post({
      url: `/analytics/session-file-download/${sessionFileId}/create/`
    })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const trackSponsorBrochureDownload = ({
  sponsorId
}: {
  sponsorId: number;
}) =>
  requestSdk(() =>
    post({
      url: `/analytics/sponsor-brochure-download/${sponsorId}/create/`
    })
  );

export const meetingSlotsRetrieve = ({
  participantId,
  context
}: {
  participantId: number;
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<IMeetingSlotsRetrieveResponse>(() =>
    get({ url: `/events/meetings/slots/${participantId}/retrieve/`, context })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const meetingCreate = ({
  data
}: {
  data: {
    participant: number;
    start: string;
  };
}) => requestSdk(() => post({ url: '/events/meetings/create/', data }));

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const meetingCancel = ({ meetingId }: { meetingId: number }) =>
  requestSdk(() => post({ url: `/events/meetings/${meetingId}/cancel/` }));

export const getMeetingJitsiInfo = ({ meetingUUID }: { meetingUUID: string }) =>
  requestSdk<IMeetingAndRoundTableJitsiInfoResponse>(() =>
    get({ url: `/events/meetings/${meetingUUID}/jitsi-info/` })
  );

export const getRoundTableJitsiInfo = ({
  roundTableJitsiRoomName
}: {
  roundTableJitsiRoomName: string;
}) =>
  requestSdk<IMeetingAndRoundTableJitsiInfoResponse>(() =>
    get({ url: `/events/round-tables/${roundTableJitsiRoomName}/jitsi-info/` })
  );

export const userRetrieve = ({
  userId,
  context
}: {
  userId: number;
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<IUserRetrieveUser>(() =>
    get({ url: `/users/${userId}/retrieve/`, context })
  );

export const getUnreadMessageCount = ({
  context
}: {
  context?: GetServerSidePropsContext;
} = {}) =>
  requestSdk<IUnreadMessagesCountResponse>(() =>
    get({ url: `/chat/private/unread-messages/count/`, context })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const signSpeakerSessionAnalytics = ({
  sessionId
}: {
  sessionId: number;
}) =>
  requestSdk<IHolisticsSign>(() =>
    post({ url: `/events/speaker/sessions/${sessionId}/sign-analytics/` })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const enquireSpeakerSessionAnalytics = ({
  sessionId
}: {
  sessionId: number;
}) =>
  requestSdk(() =>
    post({ url: `/events/speaker/sessions/${sessionId}/enquire-analytics/` })
  );

export const fetchAdBanner = ({
  context
}: {
  context?: GetServerSidePropsContext;
} = {}) =>
  requestSdk<IAdBanner | null>(() =>
    get({ url: '/announcements/ba/retrieve/', context })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const trackAdBannerViewImpression = ({
  adCampaignId
}: {
  adCampaignId: number;
}) =>
  requestSdk(() =>
    post({
      url: `/analytics/ba/${adCampaignId}/impression/create/`
    })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const trackAdBannerClickImpression = ({
  adCampaignId
}: {
  adCampaignId: number;
}) =>
  requestSdk(() =>
    post({
      url: `/analytics/ba/${adCampaignId}/click/create/`
    })
  );

export const markNotificationsSeen = ({
  context
}: {
  context?: GetServerSidePropsContext;
} = {}) =>
  requestSdk(() => post({ url: '/notifications/mark-seen/', context }));

export const fetchNotifications = ({
  context
}: {
  context?: GetServerSidePropsContext;
} = {}) =>
  requestSdk<PaginatedList<INotificationListNotification>>(() =>
    get({ url: '/notifications/list/', context })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const fetchNextNotifications = ({ nextUrl }: { nextUrl: string }) =>
  requestSdk<PaginatedList<INotificationListNotification>>(() =>
    get({ url: nextUrl, apiUrl: '' })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const createUserViewedProfileNotification = ({
  userId
}: {
  userId: number;
}) =>
  requestSdk(() =>
    post({ url: `/notifications/viewed-user-profile/${userId}/create/` })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const setQuickGuideModalOpened = () => {
  requestSdk(() =>
    post({ url: '/events/event/set-quick-guide-modal-opened/' })
  );
};

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const makeSessionLive = ({ sessionId }: { sessionId: number }) =>
  requestSdk(() =>
    post({ url: `/events/speaker/sessions/${sessionId}/make-live/` })
  );

export const fetchSpeakerSessionAttendeeInvitations = ({
  sessionId,
  context
}: {
  sessionId: number;
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<Array<ISpeakerSessionAttendeeInvitationListAttendeeInvitation>>(
    () =>
      get({
        url: `/events/speaker/sessions/${sessionId}/attendee-invitation/list/`,
        context
      })
  );

export const updateSpeakerSessionAttendeeInvitations = ({
  sessionId,
  data
}: {
  sessionId: number;
  data: { invite_emails: Array<string> };
}) =>
  requestSdk<Array<ISpeakerSessionAttendeeInvitationListAttendeeInvitation>>(
    () =>
      patch({
        url: `/events/speaker/sessions/${sessionId}/attendee-invitation/update/`,
        data
      })
  );

export const featuredEventList = ({
  context
}: {
  context: GetServerSidePropsContext;
}) =>
  requestSdk<PaginatedList<IFeaturedEventListEvent>>(() =>
    get({ url: '/events/event/featured/list/', context })
  );

export const eventList = ({
  context,
  params = {}
}: {
  context?: GetServerSidePropsContext;
  params?: object;
}) =>
  requestSdk<PaginatedList<IEventListEvent>>(() =>
    get({ url: '/events/event/list/', context, params })
  );

export const fetchNextEvents = ({ nextUrl }: { nextUrl: string }) =>
  requestSdk<PaginatedList<IEventListEvent>>(() =>
    get({ url: nextUrl, apiUrl: '' })
  );

export const eventTicketTypeList = ({
  context
}: {
  context: GetServerSidePropsContext;
  params?: object;
}) =>
  requestSdk<PaginatedList<IEventTicketTypeListTicketType>>(() =>
    get({ url: '/tickets/event-ticket-types/list/', context })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const createEventTicketPayment = ({
  data
}: {
  data: {
    event_ticket_type: number;
    payment_data: {
      billing_details: {
        name: string;
        email: string;
        phone: string;
        address: {
          city: string;
          country: string;
          line1: string;
          line2: string;
          postal_code: string;
        };
      };
      sales_tax_number: string;
      company_name: string;
    };
    coupon_code?: string;
  };
}) =>
  requestSdk<ICreateEventTicketPaymentResponse>(() =>
    post({ url: '/payments/create-event-ticket-payment/', data })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const roundTableDetail = ({ roundTableId }: { roundTableId: number }) =>
  requestSdk<IRoundTableDetailRoundTable>(() =>
    get({ url: `/events/round-tables/${roundTableId}/retrieve/` })
  );

export const fetchVideoLibrarySessions = ({
  context,
  params = {}
}: {
  params: object;
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<PaginatedList<IVideoLibrarySessionListSession>>(() =>
    get({ url: '/events/video-library/sessions/list/', context, params })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const fetchNextVideoLibrarySessions = ({
  nextUrl
}: {
  nextUrl: string;
}) =>
  requestSdk<PaginatedList<IVideoLibrarySessionListSession>>(() =>
    get({ url: nextUrl, apiUrl: '' })
  );

export const fetchVideoLibraryFeaturedSessions = ({
  context
}: {
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<PaginatedList<IVideoLibraryFeaturedSessionListSession>>(() =>
    get({ url: '/events/video-library/sessions/featured/list/', context })
  );

export const fetchGlobalSearchSessions = ({
  context,
  params = {}
}: {
  params: object;
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<PaginatedList<IGlobalSearchSessionListSession>>(() =>
    get({ url: '/events/global-search/sessions/list/', context, params })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const fetchNextGlobalSearchSessions = ({
  nextUrl
}: {
  nextUrl: string;
}) =>
  requestSdk<PaginatedList<IGlobalSearchSessionListSession>>(() =>
    get({ url: nextUrl, apiUrl: '' })
  );

export const fetchGlobalSearchEvents = ({
  context,
  params = {}
}: {
  context?: GetServerSidePropsContext;
  params?: object;
}) =>
  requestSdk<PaginatedList<IGlobalSearchEventListEvent>>(() =>
    get({ url: '/events/global-search/events/list/', context, params })
  );

export const fetchNextGlobalSearchEvents = ({ nextUrl }: { nextUrl: string }) =>
  requestSdk<PaginatedList<IGlobalSearchEventListEvent>>(() =>
    get({ url: nextUrl, apiUrl: '' })
  );

export const fetchGlobalSearchUsers = ({
  context,
  params = {}
}: {
  context?: GetServerSidePropsContext;
  params?: object;
}) =>
  requestSdk<PaginatedList<IGlobalSearchUserListUser>>(() =>
    get({ url: '/users/global-search/list/', context, params })
  );

export const fetchNextGlobalSearchUsers = ({ nextUrl }: { nextUrl: string }) =>
  requestSdk<PaginatedList<IGlobalSearchUserListUser>>(() =>
    get({ url: nextUrl, apiUrl: '' })
  );

export const fetchPayment = ({
  paymentId,
  context
}: {
  paymentId: string | string[] | number;
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<IPaymentRetrievePayment>(() =>
    get({ url: `/payments/${paymentId}/retrieve/`, context })
  );

export const validateCoupon = ({ couponCode }: { couponCode: string }) =>
  requestSdk<IValidateCouponResponse>(() =>
    post({ url: '/payments/coupon/validate/', data: { code: couponCode } })
  );

export const calculateEventTicketPrice = ({
  eventTicketTypeId,
  couponCode,
  countryCode,
  salesTaxNumber
}: {
  eventTicketTypeId: number;
  couponCode?: string;
  countryCode?: string;
  salesTaxNumber?: string;
}) =>
  requestSdk<ICalculateEventTicketPriceResponse>(() =>
    post({
      url: '/payments/calculate-event-ticket-price/',
      data: {
        event_ticket_type: eventTicketTypeId,
        coupon_code: couponCode,
        sales_tax_number: salesTaxNumber,
        country_code: countryCode
      }
    })
  );

export const fetchEventTicketType = ({
  eventTicketTypeId,
  context
}: {
  eventTicketTypeId: number | string | string[];
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<IEventTicketTypeRetrieveEventTicketType>(() =>
    get({
      url: `/tickets/event-ticket-types/${eventTicketTypeId}/retrieve/`,
      context
    })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const claimFreeEventTicket = ({
  eventTicketTypeId
}: {
  eventTicketTypeId: number;
}) =>
  requestSdk(() =>
    post({
      url: `/tickets/event-ticket-types/${eventTicketTypeId}/claim-free-ticket/`
    })
  );

export const organiserEventList = ({
  context,
  organiserSlug,
  params = {}
}: {
  organiserSlug: string | string[];
  context?: GetServerSidePropsContext;
  params?: object;
}) =>
  requestSdk<PaginatedList<IOrganiserEventListEvent>>(() =>
    get({ url: `/events/organisers/${organiserSlug}/events/`, context, params })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const organiserEventListNext = ({ nextUrl }: { nextUrl: string }) =>
  requestSdk<PaginatedList<IOrganiserEventListEvent>>(() =>
    get({ url: nextUrl, apiUrl: '' })
  );

export const organiserSessionList = ({
  context,
  organiserSlug,
  params = {}
}: {
  organiserSlug: string | string[];
  context?: GetServerSidePropsContext;
  params?: object;
}) =>
  requestSdk<PaginatedList<IOrganiserSessionListSession>>(() =>
    get({
      url: `/events/organisers/${organiserSlug}/sessions/`,
      context,
      params
    })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const organiserSessionListNext = ({ nextUrl }: { nextUrl: string }) =>
  requestSdk<PaginatedList<IOrganiserSessionListSession>>(() =>
    get({ url: nextUrl, apiUrl: '' })
  );

export const organiserMemberList = ({
  context,
  organiserSlug,
  params = {}
}: {
  organiserSlug: string | string[];
  context?: GetServerSidePropsContext;
  params?: object;
}) =>
  requestSdk<PaginatedList<IOrganiserMemberListMember>>(() =>
    get({
      url: `/users/organisers/${organiserSlug}/members/`,
      context,
      params
    })
  );

// Doesn't have the context argument, because it shouldn't be executed on the server.
export const organiserMemberListNext = ({ nextUrl }: { nextUrl: string }) =>
  requestSdk<PaginatedList<IOrganiserMemberListMember>>(() =>
    get({ url: nextUrl, apiUrl: '' })
  );

export const organiserRetrieve = ({
  context,
  organiserSlug
}: {
  organiserSlug: string | string[];
  context?: GetServerSidePropsContext;
}) =>
  requestSdk<IOrganiserRetrieveOrganiser>(() =>
    get({
      url: `/events/organisers/${organiserSlug}/retrieve/`,
      context
    })
  );

// Requests to the FE server go here 👇
export const login = ({ authToken }: { authToken?: string | string[] }) =>
  requestSdk(() =>
    post({
      url: '/api/login/',
      data: { token: authToken },
      apiUrl: ''
    })
  );

export const refreshToken = () =>
  requestSdk(() =>
    post({
      url: '/api/refresh-token/',
      apiUrl: ''
    })
  );

export const logout = () =>
  requestSdk(() =>
    post({
      url: '/api/logout/',
      apiUrl: ''
    })
  );
