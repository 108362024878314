import axios from 'axios';
import type { GetServerSidePropsContext, NextPageContext } from 'next';

import { API_URL } from 'libs/constants';
import { getRequestHeaders } from 'libs/headers';
import { IResponse } from 'libs/types';

export const getConfig = () => ({
  'Content-Type': 'application/json'
});

export const get = ({
  url,
  context,
  params = {},
  apiUrl = API_URL
}: {
  url: String;
  params?: object;
  apiUrl?: string;
  context?: GetServerSidePropsContext | NextPageContext;
}) =>
  axios.get(`${apiUrl}${url}`, {
    ...getConfig(),
    headers: getRequestHeaders({ context }),
    params
  });

export const post = ({
  url,
  data,
  context,
  params = {},
  apiUrl = API_URL
}: {
  url: string;
  data?: any;
  params?: object;
  apiUrl?: string;
  context?: GetServerSidePropsContext | NextPageContext;
}) =>
  axios.post(`${apiUrl}${url}`, data, {
    ...getConfig(),
    headers: getRequestHeaders({ context }),
    params
  });

export const patch = ({
  url,
  data,
  context,
  params = {},
  apiUrl = API_URL
}: {
  url: string;
  data: any;
  params?: {};
  apiUrl?: string;
  context?: GetServerSidePropsContext | NextPageContext;
}) =>
  axios.patch(`${apiUrl}${url}`, data, {
    ...getConfig(),
    headers: getRequestHeaders({ context }),
    params
  });

export const requestSdk = async <IResult,>(
  sdk: CallableFunction
): Promise<IResponse<IResult>> => {
  const result: IResponse<IResult> = {
    error: {
      data: {},
      status: null
    },
    success: false
  };

  try {
    const response = await sdk();

    result.data = response.data;
    result.success = true;
  } catch (error) {
    if (!error.response) {
      result.error = { data: {}, status: null, statusText: error.toString() };

      return result;
    }

    const { data, status, statusText } = error.response;

    result.error = {
      data,
      status,
      statusText
    };
  }
  return result;
};
