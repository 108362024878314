import { handleMobileChatStateChange } from 'libs/common';
import {
  IMeUser,
  IAppState,
  IReducerAction,
  IEventDetailEvent,
  IUserRetrieveUser
} from 'libs/types';

export const initialState: IAppState = {
  userDetailUser: null,
  userDetailUserId: null,
  isMobileChatOpen: false,
  isFetchingInitials: true // initial value has to be true so that login required pages don't redirect before the call is made
};

export const setIsFetchingInitials = (
  isFetchingInitials: boolean
): IReducerAction => ({
  type: 'SET_IS_FETCHING_INITIALS',
  payload: { isFetchingInitials }
});

export const setUser = (user: IMeUser): IReducerAction => ({
  type: 'SET_USER',
  payload: { user }
});

export const setEvent = (
  event: IEventDetailEvent | undefined
): IReducerAction => ({
  type: 'SET_EVENT',
  payload: { event }
});

export const setUserDetailUserId = (userId: number | null): IReducerAction => ({
  type: 'SET_USER_DETAIL_USER_ID',
  payload: { userId }
});

export const setUserDetailUser = (
  user: IUserRetrieveUser | null
): IReducerAction => ({
  type: 'SET_USER_DETAIL_USER',
  payload: { user }
});

export const setIsMobileChatOpen = (
  isMobileChatOpen: boolean
): IReducerAction => ({
  type: 'SET_IS_MOBILE_CHAT_OPEN',
  payload: { isMobileChatOpen }
});

export const reducer = (
  state: IAppState,
  action: IReducerAction
): IAppState => {
  switch (action.type) {
    case 'SET_EVENT':
      state = { ...state, event: action.payload.event };
      break;
    case 'SET_USER':
      state = { ...state, user: action.payload.user };
      break;
    case 'SET_IS_FETCHING_INITIALS':
      state = {
        ...state,
        isFetchingInitials: action.payload.isFetchingInitials
      };
      break;
    case 'SET_USER_DETAIL_USER':
      state = {
        ...state,
        userDetailUser: action.payload.user
      };
      break;
    case 'SET_USER_DETAIL_USER_ID':
      state = {
        ...state,
        userDetailUserId: action.payload.userId,
        // if we're setting the id we need to open the mobile chat
        isMobileChatOpen: action.payload.userId ? true : state.isMobileChatOpen
      };
      handleMobileChatStateChange({ isMobileChatOpen: state.isMobileChatOpen });

      break;
    case 'SET_IS_MOBILE_CHAT_OPEN':
      state = {
        ...state,
        isMobileChatOpen: action.payload.isMobileChatOpen,
        // reset User Detail user id on chat close
        userDetailUserId: action.payload.isMobileChatOpen
          ? state.userDetailUserId
          : null
      };
      handleMobileChatStateChange({
        isMobileChatOpen: state.isMobileChatOpen
      });

      break;
    default:
      throw new Error('Invalid action.');
  }

  return state;
};
