import _ from 'lodash';
import { useRouter } from 'next/router';
import { useEffect, useState, useCallback } from 'react';

import { setUser, setIsFetchingInitials } from 'libs/reducer';
import { userDetail } from 'libs/requests';
import { IAppState } from 'libs/types';

// In the general case this should only be used in the app component.
// Contact @Daniel if you have a more complicated case.
const useFetchInitials = (context: IAppState) => {
  // can't use `useContext` here because we can be outside the provider

  // We use "blockCalls" so that we don't make any additional calls
  // while we're actually in the process of fetching the user.
  // "isFetchingInitials" is initially "true" so it can't be used for this.
  const [blockCalls, setBlockCalls] = useState(false);

  const router = useRouter();

  const { user, dispatch, isFetchingInitials } = context;

  const fetchUser = useCallback(async () => {
    if (blockCalls) {
      return;
    }

    setBlockCalls(true);

    const { data, success } = await userDetail();

    if (success && data && dispatch) {
      dispatch(setUser(data));
    }

    if (dispatch) {
      dispatch(setIsFetchingInitials(false));
    }

    setBlockCalls(false);
  }, [dispatch, blockCalls]);

  useEffect(() => {
    if (!isFetchingInitials) {
      return;
    }

    if (_.isNil(user)) {
      fetchUser();
    } else if (dispatch) {
      dispatch(setIsFetchingInitials(false));
    }
  }, [router.pathname, user, dispatch, fetchUser, isFetchingInitials]);
};

export default useFetchInitials;
