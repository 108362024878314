import cookie from 'cookie';
import type {
  GetServerSidePropsContext,
  NextPageContext,
  NextApiRequest
} from 'next';

import { AUTHENTICATION_COOKIE_NAME } from 'libs/constants';
import type {
  IAuthenticationHeaders,
  IEventInfoHeaders,
  IRequestHeaders
} from 'libs/types';
import { getEventSlugFromUrl } from 'libs/urls';

export const getAuthenticationHeadersFromContext = ({
  context
}: {
  context:
    | GetServerSidePropsContext
    | NextPageContext
    | { req: NextApiRequest };
}): IAuthenticationHeaders | undefined => {
  const request = context.req;

  if (request) {
    const cookies = cookie.parse(request.headers.cookie || '');
    const jwtToken = cookies[AUTHENTICATION_COOKIE_NAME];

    if (jwtToken) {
      return { Authorization: `JWT ${jwtToken}` };
    }
  }
};

export const getAuthenticationHeadersFromDocument = ():
  | IAuthenticationHeaders
  | undefined => {
  const cookies = cookie.parse(document.cookie || '');
  const jwtToken = cookies[AUTHENTICATION_COOKIE_NAME];

  if (jwtToken) {
    return { Authorization: `JWT ${jwtToken}` };
  }
};

export const formatEventInfoHeaders = ({
  eventSlug
}: {
  eventSlug: string | undefined;
}): IEventInfoHeaders => {
  if (eventSlug) {
    return { 'Event-Info': eventSlug };
  }

  return {};
};

export const getEventInfoHeadersFromContext = ({
  context
}: {
  context: GetServerSidePropsContext | NextPageContext;
}): IEventInfoHeaders | undefined => {
  const eventSlug = context.query?.eventSlug;

  if (eventSlug) {
    return formatEventInfoHeaders({ eventSlug: eventSlug as string });
  }
};

export const getHeadersFromContext = ({
  context
}: {
  context: GetServerSidePropsContext | NextPageContext;
}): IRequestHeaders => {
  return {
    ...getAuthenticationHeadersFromContext({ context }),
    ...getEventInfoHeadersFromContext({ context })
  };
};

export const getHeadersFromBrowser = () => ({
  ...formatEventInfoHeaders({
    eventSlug: getEventSlugFromUrl()
  }),
  ...getAuthenticationHeadersFromDocument()
});

export const getRequestHeaders = ({
  context
}: {
  context?: GetServerSidePropsContext | NextPageContext;
}) => {
  if (context) {
    return getHeadersFromContext({ context });
  }

  if (process.browser) {
    return getHeadersFromBrowser();
  }

  if (!context) {
    throw new Error(
      'You cannot make requests outside the client without providing context.'
    );
  }
};
