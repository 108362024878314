import _ from 'lodash';
import Pusher, { Channel } from 'pusher-js';

import { PUSHER_API_KEY, PUSHER_APP_CLUSTER, API_URL } from 'libs/constants';
import { ESessionStatuses } from 'libs/enums';
import { getAuthenticationHeadersFromDocument } from 'libs/headers';
import {
  IMessage,
  IUnreadMessagesCountResponse,
  INotificationListNotification
} from 'libs/types';

/*
   Below in the class we have a collection of subscribe/bind methods.
   Each of them returns it's own "cleanup". This way we skip the need of separate
   unsubscribe/unbind methods.
 */

class PusherManagerClass {
  pusherClient: Pusher | null;
  // `[index: string]` allows this syntax: channel[channelName] with no eslint errors
  channels: { [index: string]: Channel | null };

  constructor() {
    this.pusherClient = null;
    this.channels = {};
  }

  initPusherClient() {
    if (!PUSHER_API_KEY) {
      throw Error;
    }

    return new Pusher(PUSHER_API_KEY, {
      cluster: PUSHER_APP_CLUSTER,
      authEndpoint: `${API_URL}/integrations/pusher/authenticate-client/`,
      auth: {
        headers: getAuthenticationHeadersFromDocument()
      }
    });
  }

  updateAuthenticationHeader() {
    if (this.pusherClient?.config?.auth) {
      this.pusherClient.config.auth.headers = {
        ...this.pusherClient.config.auth.headers,
        ...getAuthenticationHeadersFromDocument()
      };
    }
  }

  getAndCreateChannelIfDoesNotExist({
    channelName
  }: {
    channelName: string;
  }): Channel {
    let channel = this.channels[channelName];

    if (_.isNil(channel)) {
      const pusherClient = this.getPusherClient();
      channel = pusherClient.subscribe(channelName);
      this.channels[channelName] = channel;
    }

    return channel;
  }

  removeAndUnsubscribeChannel({ channelName }: { channelName: string }) {
    const channel = this.channels[channelName];

    if (!_.isNil(channel)) {
      const pusherClient = this.getPusherClient();

      pusherClient.unsubscribe(channelName);
      this.channels[channelName] = null;
    }
  }

  disconnect() {
    if (_.isNil(this.pusherClient)) {
      return;
    }

    this.pusherClient.disconnect();
    this.pusherClient = null;
  }

  getPusherClient() {
    if (_.isNil(this.pusherClient)) {
      this.pusherClient = this.initPusherClient();
    }

    return this.pusherClient;
  }

  bindToLiveChatMessageEvent({
    chatId,
    eventId,
    eventHandler
  }: {
    chatId: number;
    eventId: number;
    eventHandler: (data: IMessage) => void;
  }) {
    const channelName = EVENT_SPECIFIC_LIVE_CHAT_CHANNEL_NAME({
      chatId,
      eventId
    });
    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });

    const eventName = NEW_LIVE_MESSAGE_EVENT_NAME;

    channel.unbind(eventName);
    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName);
    };
  }

  bindToNewPrivateMessageEvent({
    userId,
    eventId,
    eventHandler
  }: {
    userId: number;
    eventId: number;
    eventHandler: (data: IMessage) => void;
  }) {
    const channelName = EVENT_SPECIFIC_USER_SPECIFIC_CHANNEL_NAME({
      userId,
      eventId
    });
    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });

    const eventName = NEW_PRIVATE_MESSAGE_EVENT_NAME;

    channel.unbind(eventName);
    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName);
    };
  }

  bindToUnreadMessageCountEvent({
    userId,
    eventId,
    eventHandler
  }: {
    userId: number;
    eventId: number;
    eventHandler: (data: IUnreadMessagesCountResponse) => void;
  }) {
    const channelName = EVENT_SPECIFIC_USER_SPECIFIC_CHANNEL_NAME({
      userId,
      eventId
    });
    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });

    const eventName = UNREAD_MESSAGE_COUNT_UPDATED_EVENT_NAME;

    channel.unbind(eventName);
    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName);
    };
  }

  bindToNewNotificationEvent({
    userId,
    eventId,
    eventHandler
  }: {
    userId: number;
    eventId: number;
    eventHandler: (data: INotificationListNotification) => void;
  }) {
    const channelName = EVENT_SPECIFIC_USER_SPECIFIC_CHANNEL_NAME({
      userId,
      eventId
    });
    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });

    const eventName = NEW_NOTIFICATION_EVENT_NAME;

    channel.unbind(eventName);
    channel.bind(eventName, eventHandler);

    return () => {
      channel.unbind(eventName);
    };
  }

  bindToEventLiveStatusHasBeenUpdated({
    eventId,
    eventHandler
  }: {
    eventId: number;
    eventHandler: ({
      eventId,
      isLive
    }: {
      eventId: number;
      isLive: boolean;
    }) => void;
  }) {
    const channelName = EVENT_SPECIFIC_EVENT_LIFE_CYCLE_CHANNEL_NAME({
      eventId
    });
    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });

    const eventName = EVENT_LIVE_STATUS_HAS_BEEN_UPDATED_EVENT_NAME;

    channel.unbind(eventName);
    channel.bind(
      eventName,
      ({
        event_id: eventId,
        is_live: isLive
      }: {
        event_id: number;
        is_live: boolean;
      }) => eventHandler({ eventId, isLive })
    );

    return () => {
      channel.unbind(eventName);
    };
  }

  bindToSessionLiveCountUpdated({
    eventId,
    sessionId,
    eventHandler
  }: {
    eventId: number;
    sessionId: number;
    eventHandler: ({ liveCount }: { liveCount: number }) => void;
  }) {
    const channelName = EVENT_SPECIFIC_SESSION_EVENTS_CHANNEL_NAME({
      eventId,
      sessionId
    });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });

    const eventName = SESSION_LIVE_COUNT_UPDATED_EVENT_NAME;

    channel.unbind(eventName);
    channel.bind(
      eventName,
      ({ live_count: liveCount }: { live_count: number }) =>
        eventHandler({ liveCount })
    );

    return () => {
      channel.unbind(eventName);
    };
  }

  bindToSessionUpdated({
    eventId,
    sessionId,
    eventHandler
  }: {
    eventId: number;
    sessionId: number;
    eventHandler: ({
      id,
      status,
      streamingPlaybackURL
    }: {
      id: number;
      status: ESessionStatuses;
      streamingPlaybackURL?: string;
    }) => void;
  }) {
    const channelName = EVENT_SPECIFIC_SESSION_EVENTS_CHANNEL_NAME({
      eventId,
      sessionId
    });

    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });

    const eventName = SESSION_UPDATED_EVENT_NAME;

    channel.unbind(eventName);
    channel.bind(
      eventName,
      ({
        id,
        status,
        streaming_playback_url: streamingPlaybackURL
      }: {
        id: number;
        status: ESessionStatuses;
        streaming_playback_url?: string;
      }) => eventHandler({ id, status, streamingPlaybackURL })
    );

    return () => {
      channel.unbind(eventName);
    };
  }

  bindToSessionUpdatedForSpeaker({
    userId,
    eventId,
    eventHandler
  }: {
    userId: number;
    eventId: number;
    eventHandler: ({
      id,
      status,
      canMakeLive,
      isReceivingStream
    }: {
      id: number;
      canMakeLive: boolean;
      status: ESessionStatuses;
      isReceivingStream: boolean;
      streamingPlaybackUrl: string;
    }) => void;
  }) {
    const channelName = EVENT_SPECIFIC_USER_SPECIFIC_CHANNEL_NAME({
      eventId,
      userId
    });
    const channel = this.getAndCreateChannelIfDoesNotExist({ channelName });

    const eventName = SESSION_UPDATED_FOR_SPEAKER_EVENT_NAME;

    channel.unbind(eventName);
    channel.bind(
      eventName,
      ({
        id,
        status,
        can_make_live: canMakeLive,
        is_receiving_stream: isReceivingStream,
        streaming_playback_url: streamingPlaybackUrl
      }: {
        id: number;
        can_make_live: boolean;
        status: ESessionStatuses;
        is_receiving_stream: boolean;
        streaming_playback_url: string;
      }) =>
        eventHandler({
          id,
          status,
          canMakeLive,
          isReceivingStream,
          streamingPlaybackUrl
        })
    );

    return () => {
      channel.unbind(eventName);
    };
  }

  subscribeToGlobalUserSpecificChannel({ userId }: { userId: number }) {
    const channelName = GLOBAL_USER_SPECIFIC_CHANNEL_NAME({ userId });

    this.getAndCreateChannelIfDoesNotExist({ channelName });

    return () => {
      this.removeAndUnsubscribeChannel({ channelName });
    };
  }

  subscribeToEventSpecificLiveChat({
    chatId,
    eventId
  }: {
    chatId: number;
    eventId: number;
  }) {
    const channelName = EVENT_SPECIFIC_LIVE_CHAT_CHANNEL_NAME({
      eventId,
      chatId
    });

    this.getAndCreateChannelIfDoesNotExist({ channelName });

    return () => {
      this.removeAndUnsubscribeChannel({ channelName });
    };
  }

  subscribeToEventSpecificLiveSessionAttendee({
    eventId,
    sessionId,
    attendeeId
  }: {
    eventId: number;
    sessionId: number;
    attendeeId: number;
  }) {
    const channelName = EVENT_SPECIFIC_LIVE_SESSION_ATTENDEE_CHANNEL_NAME({
      eventId,
      sessionId,
      attendeeId
    });
    this.getAndCreateChannelIfDoesNotExist({ channelName });

    return () => {
      this.removeAndUnsubscribeChannel({ channelName });
    };
  }

  subscribeToEventSpecificEventLifeCycle({ eventId }: { eventId: number }) {
    const channelName = EVENT_SPECIFIC_EVENT_LIFE_CYCLE_CHANNEL_NAME({
      eventId
    });

    this.getAndCreateChannelIfDoesNotExist({ channelName });

    return () => {
      this.removeAndUnsubscribeChannel({ channelName });
    };
  }

  subscribeToEventSpecificSessionEvents({
    sessionId,
    eventId
  }: {
    sessionId: number;
    eventId: number;
  }) {
    const channelName = EVENT_SPECIFIC_SESSION_EVENTS_CHANNEL_NAME({
      eventId,
      sessionId
    });

    this.getAndCreateChannelIfDoesNotExist({ channelName });

    return () => {
      this.removeAndUnsubscribeChannel({ channelName });
    };
  }

  subscribeToEventSpecificUserSpecificChannel({
    userId,
    eventId
  }: {
    userId: number;
    eventId: number;
  }) {
    const channelName = EVENT_SPECIFIC_USER_SPECIFIC_CHANNEL_NAME({
      userId,
      eventId
    });

    this.getAndCreateChannelIfDoesNotExist({ channelName });

    return () => {
      this.removeAndUnsubscribeChannel({ channelName });
    };
  }
}

// Channel names
const GLOBAL_USER_SPECIFIC_CHANNEL_NAME = ({ userId }: { userId: number }) =>
  `private-global-user-specific-${userId}`;

const EVENT_SPECIFIC_LIVE_CHAT_CHANNEL_NAME = ({
  eventId,
  chatId
}: {
  eventId: number;
  chatId: number;
}) => `private-event-specific-live-chat-${eventId}-${chatId}`;

const EVENT_SPECIFIC_LIVE_SESSION_ATTENDEE_CHANNEL_NAME = ({
  eventId,
  sessionId,
  attendeeId
}: {
  eventId: number;
  sessionId: number;
  attendeeId: number;
}) =>
  `private-event-specific-live-session-attendee-${eventId}-${sessionId}-${attendeeId}`;

const EVENT_SPECIFIC_EVENT_LIFE_CYCLE_CHANNEL_NAME = ({
  eventId
}: {
  eventId: number;
}) => `private-event-specific-event-life-cycle-${eventId}`;

const EVENT_SPECIFIC_SESSION_EVENTS_CHANNEL_NAME = ({
  eventId,
  sessionId
}: {
  eventId: number;
  sessionId: number;
}) => `private-event-specific-session-events-${eventId}-${sessionId}`;

const EVENT_SPECIFIC_USER_SPECIFIC_CHANNEL_NAME = ({
  eventId,
  userId
}: {
  eventId: number;
  userId: number;
}) => `private-event-specific-user-specific-${eventId}-${userId}`;

// Event names
const NEW_PRIVATE_MESSAGE_EVENT_NAME = 'new-private-message';
const UNREAD_MESSAGE_COUNT_UPDATED_EVENT_NAME = 'unread-message-count-updated';
const NEW_NOTIFICATION_EVENT_NAME = 'new-notification';
const EVENT_LIVE_STATUS_HAS_BEEN_UPDATED_EVENT_NAME =
  'event-live-status-has-been-updated';
const SESSION_LIVE_COUNT_UPDATED_EVENT_NAME = 'session-live-count-updated';
const SESSION_UPDATED_EVENT_NAME = 'session-updated';
const SESSION_UPDATED_FOR_SPEAKER_EVENT_NAME = 'session-updated-for-speaker';
const NEW_LIVE_MESSAGE_EVENT_NAME = 'new-live-message';

export default new PusherManagerClass();
