import _ from 'lodash';
import getConfig from 'next/config';

// this config is available both on the server & client but not in the test environment
const { publicRuntimeConfig = {} } = getConfig() || {};

// Google Analytics
export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_TRACKING_ID;

// Hotjar
export const HOTJAR_SNIPPET_VERSION = 6;
export const HOTJAR_ID = _.toNumber(process.env.NEXT_PUBLIC_HOTJAR_ID);

// Sentry
export const SENTRY_ENVIRONMENT = process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT;
export const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN;

// Generic
export const API_URL = process.env.NEXT_PUBLIC_API_URL;
export const DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const M3U8_CONTENT_TYPE = 'application/x-mpegURL';
export const DEFAULT_AVATAR_PATH = `${_.get(
  publicRuntimeConfig,
  'assetPrefix',
  ''
)}/images/defaultAvatar.png`;
export const FAQ_URL = 'https://help.unissu.com/knowledge/events-platform';
export const PRIVACY_POLICY_URL = 'https://www.unissu.com/privacy-policy';
export const TERMS_AND_CONDITIONS_URL =
  'https://www.unissu.com/terms-and-conditions';

// Authentication
export const AUTHENTICATION_COOKIE_NAME =
  process.env.NEXT_PUBLIC_AUTHENTICATION_COOKIE_NAME || '_connect';

// Unissu
export const UNISSU_FE_URL = process.env.NEXT_PUBLIC_UNISSU_FE_URL;
export const UNISSU_FE_LOGIN_URL = `${UNISSU_FE_URL}/connect-sso/login`;
export const UNISSU_FE_SIGNUP_URL = `${UNISSU_FE_URL}/connect-sso/signup`;
export const UNISSU_USER_DETAIL_URL = ({ userId }: { userId: number }) =>
  `${UNISSU_FE_URL}/user/${userId}`;
export const UNISSU_EDIT_PROFILE_URL = `${UNISSU_FE_URL}/profile/edit`;

// Image Placeholders
export const EVENT_LOGO_PLACEHOLDER = '/images/eventLogoPlaceholder.png';
export const ORGANISER_LOGO_PLACEHOLDER =
  '/images/organiserLogoPlaceholder.png';
export const SESSION_THUMBNAIL_PLACEHOLDER =
  '/images/sessionThumbnailPlaceholder.jpg';
export const EVENT_HERO_AND_CARD_IMAGE_PLACEHOLDER =
  '/images/eventHeroAndCardImagePlaceholder.jpg';

// MUX
export const MUX_DATA_KEY = process.env.NEXT_PUBLIC_MUX_DATA_KEY;
export const MANDATORY_MUX_DATA_METADATA = {
  player_name: 'Video.js',
  player_version: '7.8.4',
  player_init_time: Date.now(),
  env_key: MUX_DATA_KEY
};

// STRIPE
export const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY;

// GTM
export const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID || '';
// Holistics
export const HOLISTICS_IFRAME_URL = ({
  embedCode,
  token
}: {
  embedCode: string;
  token: string;
}) => `https://secure.holistics.io/embed/${embedCode}?_token=${token}`;

// Meetings
export const MEETING_IFRAME_URL = ({ meetingUUID }: { meetingUUID: string }) =>
  `https://p2p.meet.unissu.com/${meetingUUID}`;

// Pusher
export const PUSHER_API_KEY = process.env.NEXT_PUBLIC_PUSHER_APP_KEY;
export const PUSHER_APP_CLUSTER = process.env.NEXT_PUBLIC_PUSHER_APP_CLUSTER;

// Jitsi
// https://developer.8x8.com/jaas/docs/iframe-api-integration
export const JITSI_EXTERNAL_API_LOADING_SCRIPT =
  'https://8x8.vc/external_api.js';
export const JITSI_MEET_DOMAIN = '8x8.vc';

// ETC
export const REGISTER_FOR_FUTURE_EVENTS_URL =
  'https://share.hsforms.com/1dIdxSfb0T-GysuGdli5XDQ4i5yz';
export const HOST_YOUR_OWN_CONFERENCE_URL =
  'https://share.hsforms.com/1xD8Wq7QXQ82aGjz7DWLGBQ4i5yz';

// URLs
export const FORBIDDEN_URL = '/403';
export const NOT_FOUND_URL = '/404';
export const ERROR_URL = '/500';

export const HOME_URL = '/';
export const VIDEO_LIBRARY_URL = '/video-library';

export const LIVE_URL = ({ eventSlug }: { eventSlug: string }) =>
  `/${eventSlug}/live`;
export const SESSIONS_URL = ({ eventSlug }: { eventSlug: string }) =>
  `/${eventSlug}/sessions`;
export const SCHEDULE_URL = ({ eventSlug }: { eventSlug: string }) =>
  `/${eventSlug}/schedule`;
export const MEETING_URL = ({
  eventSlug,
  meetingUUID
}: {
  eventSlug: string;
  meetingUUID: string;
}) => `/${eventSlug}/meeting/${meetingUUID}`;
export const CATEGORY_LIST_URL = ({
  eventSlug,
  category
}: {
  eventSlug: string;
  category: string;
}) => `/${eventSlug}/sessions/${category}`;
export const SESSION_DETAIL_URL = ({
  eventSlug,
  sessionId,
  sessionSlug
}: {
  eventSlug: string;
  sessionId: number | string;
  sessionSlug?: string;
}) => `/${eventSlug}/session/${sessionId}/${sessionSlug}`;
export const CATEGORY_DETAIL_URL = ({
  eventSlug,
  categorySlug
}: {
  eventSlug: string;
  categorySlug: string;
}) => `/${eventSlug}/channel/${categorySlug}`;
export const SESSION_SPEAKER_DETAIL_URL = ({
  eventSlug,
  sessionId,
  sessionSlug
}: {
  eventSlug: string;
  sessionId: number | string;
  sessionSlug?: string;
}) => `/${eventSlug}/speaker/session/${sessionId}/${sessionSlug}`;
export const SESSION_PURCHASE_URL = ({ eventSlug }: { eventSlug: string }) =>
  `/${eventSlug}/session-purchase`;
export const SESSION_PURCHASE_SUCCESS_URL = ({
  eventSlug
}: {
  eventSlug: string;
}) => `/${eventSlug}/session-purchase/success`;
export const SESSION_PURCHASE_CANCEL_URL = ({
  eventSlug
}: {
  eventSlug: string;
}) => `/${eventSlug}/session-purchase/cancel`;
export const EVENT_TICKET_PURCHASE_URL = ({
  eventSlug,
  next
}: {
  eventSlug: string;
  next?: string | string[];
}) => `/${eventSlug}/event-ticket-purchase${next ? `?next=${next}` : ''}`;
export const EVENT_TICKET_PURCHASE_SUCCESS_URL = ({
  eventSlug,
  eventTicketTypeId,
  next
}: {
  eventSlug: string;
  eventTicketTypeId: number;
  next?: string | string[];
}) =>
  `/${eventSlug}/event-ticket-purchase/${eventTicketTypeId}/success${
    next ? `?next=${next}` : ''
  }`;
export const EVENT_TICKET_PURCHASE_FREE_TICKET_SUCCESS_URL = ({
  eventSlug,
  eventTicketTypeId,
  next
}: {
  eventSlug: string;
  eventTicketTypeId: number;
  next?: string | string[];
}) =>
  `/${eventSlug}/event-ticket-purchase/${eventTicketTypeId}/free${
    next ? `?next=${next}` : ''
  }`;
export const EVENT_TICKET_PURCHASE_CANCEL_URL = ({
  eventSlug,
  eventTicketTypeId,
  next
}: {
  eventSlug: string;
  eventTicketTypeId: number;
  next?: string | string[];
}) =>
  `/${eventSlug}/event-ticket-purchase/${eventTicketTypeId}/cancel${
    next ? `?next=${next}` : ''
  }`;

export const EVENT_TICKET_PURCHASE_PAYMENT_DETAILS_URL = ({
  next,
  eventSlug,
  eventTicketTypeId
}: {
  next: string;
  eventSlug: string;
  eventTicketTypeId: number;
}) =>
  `/${eventSlug}/event-ticket-purchase/${eventTicketTypeId}/payment-details?next=${next}`;

export const FULL_ROUND_TABLE_ERROR_URL = ({
  eventSlug,
  next
}: {
  eventSlug: string;
  next: string;
}) => `/${eventSlug}/round-table/error/full${next ? `?next=${next}` : ''}`;
export const EXPIRED_ROUND_TABLE_ERROR_URL = ({
  eventSlug,
  next
}: {
  eventSlug: string;
  next?: string;
}) => `/${eventSlug}/round-table/error/expired${next ? `?next=${next}` : ''}`;
export const ROUND_TABLE_DETAIL_URL = ({
  roundTableJitsiRoomName,
  eventSlug,
  next
}: {
  roundTableJitsiRoomName: string;
  eventSlug: string;
  next: string;
}) => `/${eventSlug}/round-table/${roundTableJitsiRoomName}?next=${next}`;
export const SEARCH_URL = ({ search }: { search: string }) =>
  `/search?search=${search}`;
export const ORGANISER_DETAIL_URL = ({
  organiserSlug
}: {
  organiserSlug: string;
}) => `/profile/${organiserSlug}`;
