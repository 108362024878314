import { ITheme } from 'libs/types';

export const breakpoints = {
  wide: '(min-width: 1440px)',
  desktop: '(max-width: 1439px)',
  tablet: '(max-width: 1024px)',
  mobile: '(max-width: 576px)'
};

export const red = '#DB3A34';
export const lightBlueGrey = '#F6F7F9';
export const blue = '#0095D4';
export const strongBlue = '#188BC0';
export const darkBlue = '#30596E';
export const darkGrey = '#212529';
export const lightGrey = '#65676B';
export const yellow = '#E0BA29';
export const lightBlue = '#F4F8FD';

interface IButtonColorOptions {
  [key: number]: {
    background: string;
    color: string;
    border: string;
  };
}

const buttonColorOptions = ({
  theme
}: {
  theme: ITheme;
}): IButtonColorOptions => ({
  1: {
    background: theme.primaryColor,
    color: 'white',
    border: theme.primaryColor
  },
  2: {
    background: lightBlueGrey,
    color: darkBlue,
    border: 'rgba(123, 124, 129, 0.2)'
  },
  3: { background: 'white', color: darkBlue, border: darkBlue },
  4: { background: red, color: 'white', border: red },
  5: { background: 'white', color: 'black', border: 'white' },
  6: { background: yellow, color: 'white', border: yellow }
});

export const colors = {
  buttons: buttonColorOptions,
  text: {
    1: darkGrey,
    2: lightGrey,
    3: darkBlue,
    4: blue,
    5: yellow,
    6: red
  }
};

export const STRIPE_CARD_ELEMENT_STYLES = {
  base: {
    fontSize: '16px',
    color: colors.text[1],
    '::placeholder': {
      color: colors.text[2]
    }
  },
  invalid: {
    color: colors.text[6]
  }
};
