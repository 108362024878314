import Router from 'next/router';

export const getEventSlugFromUrl = (): string | undefined => {
  if (!process.browser) {
    return;
  }

  const { eventSlug }: { eventSlug?: string } = Router.query;

  return eventSlug;
};
