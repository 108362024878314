import * as Sentry from '@sentry/browser';

import { SENTRY_DSN, SENTRY_ENVIRONMENT } from 'libs/constants';

export const initSentry = () => {
  if (SENTRY_ENVIRONMENT) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENVIRONMENT
    });
  }
};
