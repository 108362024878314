import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { colors, red, blue } from 'styles/global';
import NProgressStyles from 'styles/nprogress';

export default createGlobalStyle`
  ${normalize}

  ${NProgressStyles}

  body {
    margin: 0;
    height: 100%;
    width: 100%;
    font-family: Barlow;
    color: ${colors.text[1]};

    h1, h2, h3, h4, h5, h6, p {
      margin: unset;
    }
  }

  // Toastify custom styles
  .Toastify__toast--error {
    background-color: ${red};
  }

  .Toastify__toast--success {
    background-color: ${blue}
  }

  ::-webkit-scrollbar {
    height: 4.5px;
    width: 4.5px;
    background: #adadac1a;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    opacity: 0.3;
    background: #adadac4d;
  }
`;
