import { readFileSync } from 'fs';
import _ from 'lodash';
import { toast } from 'react-toastify';

import { IError } from 'libs/types';

export const emailIsValid = (email: string) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const formatFormErrors = (errors: Array<IError>) =>
  _.mapValues(errors, (messages: Array<string>) => messages.join(' '));

export const disableBodyScroll = () => {
  if (document.body.style.position === 'fixed') {
    // do not re-disable;
    return;
  }

  // Reference: https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
  const topOffset = window.scrollY;

  document.body.style.position = 'fixed';
  document.body.style.top = `-${topOffset}px`;
};

export const enableBodyScroll = () => {
  // Reference: https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open/
  const scrollY = document.body.style.top;
  document.body.style.position = '';
  document.body.style.top = '';
  window.scrollTo(0, parseInt(scrollY || '0') * -1);
};

export const handleMobileChatStateChange = ({
  isMobileChatOpen
}: {
  isMobileChatOpen: boolean;
}) => {
  // 1024 is the maximum tablet width we've set
  if (window.innerWidth <= 1024) {
    if (isMobileChatOpen) {
      disableBodyScroll();
    } else {
      enableBodyScroll();
    }
  }
};

const clearInvalidCharacters = (string: string) =>
  string
    .replace(/[\n\r]+/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .replace(/^\s+|\s+$/, '');

export const convertCSVToObjects = ({
  filePath,
  delimiter = ','
}: {
  filePath: string;
  delimiter?: string;
}) => {
  const data = readFileSync(filePath, { encoding: 'utf-8' });

  const titles = data.slice(0, data.indexOf('\n')).split(delimiter);
  const rows = data.slice(data.indexOf('\n') + 1).split('\n');

  return rows.map((row) => {
    const values = row.split(delimiter);

    const line: { [key: string]: any } = {};

    titles.forEach((title, index) => {
      const key = clearInvalidCharacters(title);
      const value = clearInvalidCharacters(values[index]);

      line[key] = value;
    });

    return line;
  });
};

export const openInNewTab = (url: string) => {
  if (window) {
    window.open(url);
  }
};

export const showGenericErrorToast = () => {
  toast('Something went wrong. Please try again later.', { type: 'error' });
};
