import { config } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
import _ from 'lodash';
import { NextPageContext } from 'next';
import Head from 'next/head';
import Router from 'next/router';
import NProgress from 'nprogress';
import React, {
  useReducer,
  useEffect,
  useCallback,
  createContext
} from 'react';
import TagManager from 'react-gtm-module';
import { hotjar } from 'react-hotjar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import 'video.js/dist/video-js.css';

import GlobalStyle from 'styles/app';
// CSS
import 'styles/fonts.css';
import { blue } from 'styles/global';

import { refreshTokenAndUpdatePusherAuth } from 'libs/auth/refreshTokenAundUpdatePusherAuth';
import {
  HOTJAR_ID,
  GA_TRACKING_ID,
  NOT_FOUND_URL,
  ERROR_URL,
  HOTJAR_SNIPPET_VERSION,
  GTM_ID
} from 'libs/constants';
import PusherManager from 'libs/pusher';
// Reducer
import { reducer, initialState, setEvent } from 'libs/reducer';
import { eventDetail } from 'libs/requests';
// Sentry
import { initSentry } from 'libs/sentry';
import { ICustomAppProps } from 'libs/types';
import { unissuLogin } from 'libs/unissuAuth';

// not imported from hooks because of circular import issues
import useFetchInitials from 'hooks/useFetchInitials';

// Font Awesome
config.autoAddCss = false; // Tell Font Awesome to skip adding the CSS automatically since it's being imported above

// NProgress - used for transition between route changes
Router.events.on('routeChangeStart', () => {
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// Sentry Error Tracking
initSentry();

export const AppContext = createContext(initialState);

const Connect = ({
  Component,
  pageProps,
  redirect,
  event: initialEvent
}: ICustomAppProps) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    event: initialEvent
  });
  useFetchInitials({ ...state, dispatch });
  const { event, user, isFetchingInitials } = state;
  const isPageLoginRequired = Component.authenticated;

  const updateIsLive = useCallback(
    ({ isLive, eventId }: { isLive: boolean; eventId: number }) => {
      if (event && _.toInteger(event.id) === _.toInteger(eventId)) {
        dispatch(setEvent({ ...event, is_live: isLive }));
      }
    },
    [event]
  );

  useEffect(() => {
    // update the store event every time the one from props changes
    dispatch(setEvent(initialEvent));
  }, [initialEvent]);

  useEffect(() => {
    // set interval for refresh token
    refreshTokenAndUpdatePusherAuth();
    const refreshTokenInterval = setInterval(
      refreshTokenAndUpdatePusherAuth,
      1800000
    ); // Every 30min

    return () => clearInterval(refreshTokenInterval);
  }, []);

  useEffect(() => {
    // subscribe to event life cycle channel
    if (user?.id && initialEvent?.id) {
      return PusherManager.subscribeToEventSpecificEventLifeCycle({
        eventId: initialEvent.id
      });
    }
  }, [user?.id, initialEvent?.id]);

  useEffect(() => {
    // subscribe to event specific user specific channel
    if (user?.id && initialEvent?.id) {
      return PusherManager.subscribeToEventSpecificUserSpecificChannel({
        userId: user.id,
        eventId: initialEvent.id
      });
    }
  }, [user?.id, initialEvent?.id]);

  useEffect(() => {
    // subscribe to global user specific channel
    if (user?.id) {
      return PusherManager.subscribeToGlobalUserSpecificChannel({
        userId: user.id
      });
    }
  }, [user?.id]);

  useEffect(() => {
    // redirect if the event fetching has failed
    if (redirect) {
      Router.push(redirect.destination, redirect.destination, redirect);
    }
  }, [redirect]);

  useEffect(() => {
    if (!event || !user?.id) {
      return;
    }

    return PusherManager.bindToEventLiveStatusHasBeenUpdated({
      eventId: event.id,
      eventHandler: updateIsLive
    });
  }, [user?.id, event, updateIsLive]);

  // Effect to authenticate routes
  useEffect(() => {
    if (user) {
      return;
    }

    // If page is authenticated and there is no user present, redirect...
    if (isPageLoginRequired && !isFetchingInitials) {
      unissuLogin();
    }
  }, [isFetchingInitials, user, isPageLoginRequired]);

  useEffect(() => {
    if (HOTJAR_ID) {
      hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION);
    }
    TagManager.initialize({
      gtmId: GTM_ID
    });
  }, []);

  return (
    <ThemeProvider
      theme={{ primaryColor: _.get(initialEvent, 'primary_color', blue) }}>
      <AppContext.Provider value={{ ...state, dispatch }}>
        <Head>
          {event && <title>{event.name}</title>}
          <meta charSet="utf-8" />
          <meta name="theme-color" content="#000000" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}></script>
          <script
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${GA_TRACKING_ID}');`
            }}></script>
        </Head>
        <GlobalStyle />
        <ToastContainer />
        {isFetchingInitials && isPageLoginRequired ? null : (
          <Component {...pageProps} />
        )}
      </AppContext.Provider>
    </ThemeProvider>
  );
};

Connect.getInitialProps = async ({ ctx }: { ctx: NextPageContext }) => {
  const eventSlug = ctx.query?.eventSlug;

  if (!eventSlug) {
    // Not on an event page.
    return {};
  }

  const { data: event, success, error } = await eventDetail({
    context: ctx
  });

  if (success) {
    return { event };
  } else {
    return {
      redirect: {
        shallow: true,
        permanent: false,
        statusCode: error.status,
        // we use NOT_FOUND_URL instead of notFound param because otherwise it redirects even when the param is undefined.
        destination: error.status === 404 ? NOT_FOUND_URL : ERROR_URL
      }
    };
  }
};

export default Connect;
